import Modal from './Modal'
import { ExclamationIcon } from '@heroicons/react/outline'  
import { Dialog } from '@headlessui/react'
import ClipLoader from 'react-spinners/ClipLoader';

function WarningModal ({title, message, onClick1, onClick2, buttonText, showWarning, handleHide, setShowWarning, disabled, isLoading }) {
  
  return (
    <Modal show={showWarning} hide={handleHide ? handleHide : () => setShowWarning(false)}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {message}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white text-base font-medium sm:ml-3 sm:w-auto sm:text-sm relative ${
              disabled
                ? 'bg-gray-400 cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2'
                : 'bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
            }`}
            onClick={disabled || isLoading ? undefined : onClick1}
            disabled={disabled || isLoading} // Fix: changed `disable` to `disabled`
          >
            <span className="invisible">{buttonText}</span>
            <span className="absolute inset-0 flex items-center justify-center">
              {isLoading ? <ClipLoader size={17} color="#ffffff" /> : buttonText}
            </span>
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-orange-600 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClick2}
          >
            Cancel
          </button>
        </div>
    </Modal>
  )
}

export default WarningModal