import React, { useState, useEffect, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import axios from 'axios';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import ReactSelect from '../../components/inputs/ReactSelect';
import useApi from '../../store/api/apiContext';
import Loader from '../../components/loaders/Loader';
import extractObjectDifferences from '../../helpers/enum/extractObjectDifferences';
import useAuth from '../../store/auth/authContext';
import { CANDIDATE_SOURCE, CANDIDATE_TYPE } from '../../helpers/selectOptions';
import COUNTRIES from '../../helpers/countryList'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import RichTextEditor from '../../components/inputs/RichTextEditor/RichTextEditor';
import NumberInput from '../../components/inputs/NumberInput';
import Toggle from '../../components/inputs/Toggle';
import ReactCreatableSelect from '../../components/inputs/ReactCreatableSelect';
import { ALL_ACCESS_UUID } from '../../config';
import useNavigateBack from '../../hooks/useNavigateBack';
import InfoTooltip from '../../components/tooltips/InfoTooltip';

function EditCandidateForm({ candidate, isLoading }) {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { editCandidate, fetchLanguages, fetchSkills, fetchUsers } = useApi();
  const { showAppNotification } = useAppNotification();
  
  const { auth } = useAuth();
  const userId = auth.currentUser.id
  const allAccess = ALL_ACCESS_UUID;
  const firstNamesInputRef = useRef(null)

  const [showEditButton, setShowEditButton] = useState(false)
  const [candidateEdits, setCandidateEdits] = useState(candidate);
  const [countryOptions, setCountryOptions] = useState();
  const [isEditing, setIsEditing] = useState(false)

  const [selectedType, setSelectedType] = useState({
    value: candidate.type,
    label: candidate.type
  })
  const [selectedCountry, setSelectedCountry] = useState()
  const [selectedNationality, setSelectedNationality] = useState({
    value: candidate.nationality,
    label: candidate.nationality
  })
  
  const [currentSkills, setCurrentSkills] = useState([])
  const [skillOptions, setSkillOptions] = useState([])
  
  const [currentLanguages, setCurrentLanguages] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])
  
  const [selectedSource, setSelectedSource] = useState({
    value: candidate.source,
    label: candidate.source
  })

  const [selectedOriginalOwner, setSelectedOriginalOwner] = useState({
    value: candidate.original_owner,
    label: `${candidate.originalOwner.first_names} ${candidate.originalOwner.last_names}`
  })
  
  const [userOptions,setUserOptions] = useState([]);
  
  const [formError, setFormError] = useState([])

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const [ candidateNotes, setCandidateNotes ] = useState(() => EditorState.createEmpty());
  const [ currentNotes, setCurrentNotes ] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    if (extractObjectDifferences(candidate, candidateEdits) || candidateNotes.getCurrentContent().getPlainText() != currentNotes.getCurrentContent().getPlainText()) {
      setShowEditButton(true)
    } else {
      setShowEditButton(false)
    }
    
  }, [candidateEdits, candidateNotes.getCurrentContent()])

  
  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchLanguages(source).then(res => {
      setLanguageOptions(res.data.map(language => ({
        value: language.id,
        label: language.name
      })))
    })
    fetchSkills(source).then(res => {
      setSkillOptions(res.data.map(skill => ({
        value: skill.id,
        label: skill.name
      })))
    })

    fetchUsers(source).then((res) => {
      const allUsers = res.data.map((user) => ({
        value: user.id,
        label: user.first_names + " " + user.last_names,
      }));
      setUserOptions(allUsers.filter((user) => user.value !== candidate.original_owner));
    });
    
    return () => {
      source.cancel();
    };
  }, [])
  
  
  useEffect(() => {
    if (candidate.notes) {
      var convertedJSON = JSON.parse(candidate.notes);
      setCandidateNotes(EditorState.createWithContent(convertFromRaw(convertedJSON)))
      setCurrentNotes(EditorState.createWithContent(convertFromRaw(convertedJSON)))
    }
  }, [candidate])
  
  useEffect(() => {
    let languages = [];
    let skills = []
    candidate.languages.forEach(language => {
      languages.push({
        value: language.id,
        label: language.name
      })
    })
    candidate.skills.forEach(skill => {
      skills.push({
        value: skill.id,
        label: skill.name
      })
    })

    setCurrentLanguages(languages)
    setCurrentSkills(skills)

    if (!languages.length) setCurrentLanguages(false)
    if (!skills.length) setCurrentSkills(false)

  }, [candidate])


  
  const postEditCandidate = async () => {
    setIsEditing(true)
    let errors = [];

    if (!candidateEdits.first_names) errors.push({
      field: "First names*",
      msg: "This field is required"
    })
    if (!candidateEdits.last_names) errors.push({
      field: "Last names*",
      msg: "This field is required"
    })
    if (!candidateEdits.email && !candidateEdits.legacy_id || !candidateEdits.email && candidate.email) errors.push({
      field: "Email*",
      msg: "This field is required"
    })
    if (candidateEdits.email && !validateEmail(candidateEdits.email)) errors.push({
      field:"Email*",
      msg: "Please provide a valid email address"
    })
    if (candidateEdits.phone_nb && !(/^[\d +]+$/.test(candidateEdits.phone_nb))) errors.push({
      field: 'Phone number',
      msg: "Can only contain numbers and (+) sign."
    })
    if (!candidateEdits.phone_nb) errors.push({
      field: 'Phone number*',
      msg: "This field is required"
    })

    if (candidateEdits?.linkedIn_handle && !(/^\S{3,100}$/.test(candidateEdits.linkedIn_handle))) errors.push({
      field: 'LinkedIn handle',
      msg: "Must be between 3-100 characters and cannot contain spaces"
    })

    if (errors.length) {
      firstNamesInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      setIsEditing(false)
      return setFormError(errors);
    }

    setFormError([]);

    let data = {...candidateEdits};


    if (!cannotEditProperty("notes")) {
      var rawContentState = convertToRaw(candidateNotes.getCurrentContent());
      var rawJSON = JSON.stringify(rawContentState)
      data.notes = rawJSON;
    }

    if (data.notice_period == '') data.notice_period = null
    if (data.type == '') data.type = null
    if (data?.linkedin_handle === '') data.linkedin_handle = null
    const updateData = extractObjectDifferences(candidate, data)
    editCandidate(candidate.id, updateData).then(res => {
      //Consider creating error handling in case there is an issue with languages or skills
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Candidate edited successfully');
      navigate(`/candidates/${candidate.id}`, {replace:true});
      setIsEditing(false)
    }).catch(err => {
      setIsEditing(false)
    })
  }

  useEffect(() => {
    let options = []
    COUNTRIES.forEach(country => {
      options.push({
        label: country.name,
        value: country.code
      })
      if (country.code == candidate.country) {
        setSelectedCountry({
          label: country.name,
          value: country.code
        })
      }
    })  
    setCountryOptions(options)
    
  }, [COUNTRIES])

  const typeOptions = CANDIDATE_TYPE.map(type => ({
    value: type, 
    label: type
  }));


  const sourceOptions = CANDIDATE_SOURCE.map(source => ({
    value: source,
    label: source
  }));  

  const changeHandlerSelectCountry = (e) => {
    setCandidateEdits(prev => ({...prev, country: e.value, role_id: null }) )
    setSelectedCountry(e)
  }

  
  const changeHandlerSelectNationality = (e) => {
    setCandidateEdits(prev => ({...prev, nationality: e.value }) )
    setSelectedNationality(e)
  }

  const changeHandlerSelectType = (e) => {
    setCandidateEdits(prev => ({...prev, type: e.value }) )
    setSelectedType(e)
  }
  
  const changeHandlerSelectLanguages = (e) => {
    let _selectedLanguages;
    if (!e) _selectedLanguages = [];
    else _selectedLanguages = e.map(el => el.value)
    setCandidateEdits(prev => ({...prev, languages: _selectedLanguages}))
  }
  
  const changeHandlerSelectSkills = (e) => {
    let _selectedSkills;
    if (!e) _selectedSkills = [];
    else _selectedSkills = e.map(el => el )
    setCandidateEdits(prev => ({...prev, skills: _selectedSkills}))
  }

  const changeHandlerSelectSource = (e) => {
    setCandidateEdits(prev => ({...prev, type: e.value}) )
    setSelectedSource(e)
  }

  const changeHandlerSelectOriginalOwner = (e) => {
    setCandidateEdits(prev => ({...prev, original_owner: e.value}) )
    setSelectedOriginalOwner(e);
  }

  const cannotEditProperty = (property) => {
    if (!auth.userRole) return;
    let editableProperties = []
    if (userId === candidate.original_owner) {
      editableProperties = auth.userRole.g_2_1_editable_properties_my_candidate
    } else {
      editableProperties = auth.userRole.g_4_3_1_editable_properties
    }

    if (editableProperties[0] == allAccess) return false;
    if (editableProperties.includes(property)) return false;
    
    return true
  }

  return (
    <div className="space-y-6">
      <Loader isLoading={isLoading}>
        <FormWithSideTitle
          title="Candidate information"
          description="Edit basic candidate information"
        >
          <div ref={firstNamesInputRef} className="col-span-6 sm:col-span-3">
            <TextInput
              label="First names*"
              disabled={cannotEditProperty("first_names")}
              errors={formError}
              value={candidateEdits.first_names}
              onChange={(e) =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  first_names: e.target.value,
                }))
              }
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <TextInput
              label="Last names*"
              disabled={cannotEditProperty("last_names")}
              errors={formError}
              value={candidateEdits.last_names}
              onChange={(e) =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  last_names: e.target.value,
                }))
              }
            />
          </div>
          <div className="col-span-6 sm:col-span-4">
            <TextInput
              label={`Email${candidate.email ? "*" : ""}`}
              disabled={cannotEditProperty("email")}
              errors={formError}
              value={candidateEdits.email}
              onChange={(e) =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
            />
          </div>
          <div className="col-span-6 sm:col-span-4">
            <TextInput
              label="Phone number*"
              disabled={cannotEditProperty("phone_nb")}
              errors={formError}
              value={candidateEdits.phone_nb}
              onChange={(e) =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  phone_nb: e.target.value,
                }))
              }
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <ReactSelect
              label="Country of residence"
              disabled={cannotEditProperty("country")}
              errors={formError}
              placeholder="Countries"
              selectedOption={selectedCountry}
              options={countryOptions}
              onChange={changeHandlerSelectCountry}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <ReactSelect
              label="Nationality"
              disabled={cannotEditProperty("nationality")}
              errors={formError}
              placeholder="Countries"
              selectedOption={selectedNationality}
              options={countryOptions}
              onChange={changeHandlerSelectNationality}
            />
          </div>
          {currentLanguages.length || !currentLanguages ? (
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect
                label="Languages"
                errors={formError}
                disabled={cannotEditProperty("languages")}
                isMulti
                selectedOptions={currentLanguages}
                options={languageOptions}
                onChange={changeHandlerSelectLanguages}
              />
            </div>
          ) : (
            <></>
          )}
        </FormWithSideTitle>
        <FormWithSideTitle
          title="Profile information"
          description="Add all relevant profile information"
        >
          <div className="col-span-6">
            <Toggle
              label="First time candidate"
              description="Toggle off if candidate has already been placed in a job order"
              disabled={cannotEditProperty("first_time_candidate")}
              errors={formError}
              value={candidateEdits.first_time_candidate}
              onChange={() =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  first_time_candidate: !candidateEdits.first_time_candidate,
                }))
              }
            />
          </div>
          <div className="col-span-6">
            <Toggle
              label="Can relocate"
              description="Toggle on if the candidate is willing to relocate"
              disabled={cannotEditProperty("can_relocate")}
              errors={formError}
              value={candidateEdits.can_relocate}
              onChange={() =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  can_relocate: !candidateEdits.can_relocate,
                }))
              }
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <ReactSelect
              label="Belongs to"
              disabled={cannotEditProperty("original_owner")}
              placeholder="Belongs to"
              errors={formError}
              selectedOption={selectedOriginalOwner}
              options={userOptions}
              onChange={changeHandlerSelectOriginalOwner}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <ReactSelect
              label="Source"
              disabled={cannotEditProperty("source")}
              placeholder="Source"
              errors={formError}
              selectedOption={selectedSource}
              options={sourceOptions}
              onChange={changeHandlerSelectSource}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <ReactSelect
              label="Type"
              disabled={cannotEditProperty("type")}
              errors={formError}
              selectedOption={selectedType}
              options={typeOptions}
              onChange={changeHandlerSelectType}
            />
          </div>
          <div className="col-span-6 lg:col-span-3">
            <TextInput
              label={<div className='flex space-x-1 items-center'><div>LinkedIn</div><div><InfoTooltip childrenClassName={'w-52 sm:w-80 z-50'}>If the candidate has several language profiles, please ignore the language field ('/fr', '/en', etc...) that LinkedIn adds at the end of the URL. </InfoTooltip></div></div>}
              disabled={cannotEditProperty("linkedin_handle")}
              errors={formError}
              value={candidateEdits.linkedin_handle}
              onChange={(e) =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  linkedin_handle: e.target.value,
                }))
              }
              inputPrefix='www.linkedin.com/in/'
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <NumberInput
              label="Notice period (days)"
              disabled={cannotEditProperty("notice_period")}
              errors={formError}
              value={candidateEdits.notice_period}
              onChange={(e) =>
                setCandidateEdits((prev) => ({
                  ...prev,
                  notice_period: e.target.value,
                }))
              }
            />
          </div>
          {currentSkills.length || !currentSkills ? (
            <div className="col-span-6 sm:col-span-3">
              <ReactCreatableSelect
                label="Skills"
                errors={formError}
                disabled={cannotEditProperty("skills")}
                isMulti
                selectedOptions={currentSkills}
                options={skillOptions}
                onChange={changeHandlerSelectSkills}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="col-span-6">
            <RichTextEditor
              label="Notes"
              editorState={candidateNotes}
              setEditorState={setCandidateNotes}
              placeholder="Write here..."
              readOnly={cannotEditProperty("notes")}
            />
          </div>
        </FormWithSideTitle>
        <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
          <WhiteButton
            text="Cancel"
            onClick={() => navigateBack(`/candidates`)}
            disabled={isEditing}
          />
          <PrimaryButton
            text="Edit"
            darkRing
            onClick={postEditCandidate}
            disabled={!showEditButton}
            isLoading={isEditing}
          />
        </div>
      </Loader>
    </div>
  );
}

export default EditCandidateForm;