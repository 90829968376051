import React, { useState, useEffect, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import NumberInput from '../../components/inputs/NumberInput'
import DateInput from '../../components/inputs/DateInput/DateInput'
import ReactSelect from '../../components/inputs/ReactSelect';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Loader from '../../components/loaders/Loader';
import { useNavigate } from 'react-router';
import useApi from '../../store/api/apiContext';
import axios from 'axios'; 
import { EDUCATION_LEVELS, SECTORS, JOB_ORDER_PLACEMENT_TYPES, JOB_ORDER_REQUEST_TYPES, JOB_ORDER_MODES, JOB_ORDER_PRIORITY, INDUSTRY_OPTIONS } from '../../helpers/selectOptions';
import COUNTRIES from '../../helpers/countryList'
import extractObjectDifferences from '../../helpers/enum/extractObjectDifferences';
import AccessWrapper from '../app/AccessWrapper';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import useAuth from '../../store/auth/authContext';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import RichTextEditor from '../../components/inputs/RichTextEditor/RichTextEditor';
import Toggle from '../../components/inputs/Toggle';
import WarningModal from '../../components/modal/WarningModal';
import { ExclamationCircleIcon } from '@heroicons/react/solid' 
import ReactCreatableSelect from '../../components/inputs/ReactCreatableSelect';
import useNavigateBack from '../../hooks/useNavigateBack'
import useJobOrders from '../../store/jobOrders/jobOrdersContext';
import TextArea from '../../components/inputs/TextArea';

function EditJobOrderForm({jobOrder, isLoading}) {
  const navigateBack = useNavigateBack();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const userId = auth.currentUser.id
  const titleInputRef = useRef(null)
  const { fetchLanguages, fetchSkills, fetchClients, fetchFrameworkContracts } = useApi();
  const { editJobOrder } = useJobOrders();
  const { verifyArrayAccess} = useUserRoleVerifier();
  const { showAppNotification } = useAppNotification();
  const [ showWarning, setShowWarning ] = useState(false)
  const [ editedJobOrder, setEditedJobOrder ] = useState({})
  const [ formError, setFormError ] = useState([])
  const [ clientOptions, setClientOptions ] = useState([])
  const [ fwCOptions, setFwCOptions ] = useState([])
  const [ selectedClient, setSelectedClient ] = useState()
  const [ selectedFwC, setSelectedFwC ] = useState()
  const [ changedFwC, setChangedFwC ] = useState()
  const [ hasConfirmedFwCChange, setHasConfirmedFwCChange ] = useState();
  const [ selectedCountry, setSelectedCountry ] = useState()
  const [ selectedSector, setSelectedSector ] = useState({
    value: jobOrder.sector,
    label: jobOrder.sector
  })
  const [ selectedEduLevel, setSelectedEduLevel ] = useState({
    value: jobOrder.education_level,
    label: jobOrder.education_level
  })
  const [ selectedPlacementType, setSelectedPlacementType ] = useState({
    value: jobOrder.placement_type,
    label: jobOrder.placement_type
  })
  const [ selectedPriority, setSelectedPriority ] = useState({
    value: jobOrder.priority,
    label: jobOrder.priority
  })
  const [ selectedRequestType, setSelectedRequestType ] = useState({
    value: jobOrder.request_type,
    label: jobOrder.request_type
  })
  const [ selectedMode, setSelectedMode ] = useState({
    value: jobOrder.mode,
    label: jobOrder.mode
  })
  const [selectedIndustry, setSelectedIndustry] = useState(jobOrder?.industry && {
    value: jobOrder.industry,
    label: jobOrder.industry
  })

  const [ countryOptions, setCountryOptions ] = useState();
  
  const [currentLanguages, setCurrentLanguages] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])
  const [showEditButton, setShowEditButton] = useState(false);
  
  const [currentSkills, setCurrentSkills] = useState([])
  const [skillOptions, setSkillOptions] = useState([])
  
  const [ currentDescription, setCurrentDescription ] = useState(() => EditorState.createEmpty())
  const [ jobOrderDescription, setJobOrderDescription ] = useState(() => EditorState.createEmpty());
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (jobOrder.description) {
      var convertedJSON = JSON.parse(jobOrder.description);
      setJobOrderDescription(EditorState.createWithContent(convertFromRaw(convertedJSON)))
      setCurrentDescription(EditorState.createWithContent(convertFromRaw(convertedJSON)))
    }
    
    let languages = []
    
    if (jobOrder?.languages?.length) languages = jobOrder?.languages.map(language => ({
      value: language.id,
      label: language.name
    }))
    let skills = []
    
    if (jobOrder?.skills?.length) skills = jobOrder?.skills.map(skill => ({
      value: skill.id,
      label: skill.name
    }))

    setCurrentLanguages(languages)
    setCurrentSkills(skills)

    if (!languages.length) setCurrentLanguages(false)
    if (!skills.length) setCurrentSkills(false)
    
  }, [jobOrder])

  useEffect(() => {

    if (extractObjectDifferences(jobOrder, editedJobOrder) || 
    jobOrderDescription.getCurrentContent().getPlainText() != currentDescription.getCurrentContent().getPlainText()) {
      setShowEditButton(true)
    } else {
      setShowEditButton(false)
    }
    
  }, [editedJobOrder, jobOrderDescription.getCurrentContent()])
 
  const fetchClientsFromApi = async(source) => {
    await fetchClients(source, 'jobOrder').then(res => {
      let clients = [];
      res.data.map(client => {
        if (client.id  === jobOrder.client_id) setSelectedClient({
          value: client.id,
          label: client.name
        })
        clients.push({
          value: client.id,
          label: client.name
        })
      })
      setClientOptions(clients)
    })
  }

  const fetchFwContracts = async(source) => {
    await fetchFrameworkContracts().then(res => {
      let fwContracts =[];
      res.data.map(fwContract => {
        if (fwContract.id === jobOrder.framework_contract_id) setSelectedFwC({
          value: fwContract.id,
          label: fwContract.contract_ref
        })
        fwContracts.push({
          value: fwContract.id,
          label: fwContract.contract_ref
        })
      })
      setFwCOptions(fwContracts)
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    setEditedJobOrder(jobOrder)
    fetchClientsFromApi(source);
    fetchFwContracts(source);
    if (verifyArrayAccess('h_3_6_1_editable_properties', 'languages')) {
      fetchLanguages(source).then(res => {
        setLanguageOptions(res.data.map(language => ({
          value: language.id,
          label: language.name
        })))
      })
    }
    if (verifyArrayAccess('h_3_6_1_editable_properties', 'skills')) {
      fetchSkills(source).then(res => {
        setSkillOptions(res.data.map(skill => ({
          value: skill.id,
          label: skill.name
        })))
      })
    }
    return () => {
      source?.cancel();
    };
  }, [])

  const changeHandlerSelectClient = (e) => {
    setEditedJobOrder(prev => ({...prev, client_id: e.value}))
    setSelectedClient(e)
  }
  const changeHandlerSelectFwC = (e) => {
    if (!hasConfirmedFwCChange && (e.value != jobOrder.framework_contract_id) ) setShowWarning(true)
    if (e.value == jobOrder.framework_contract_id) setHasConfirmedFwCChange(false)
    setChangedFwC(e)
  }
  const confirmFWCChange = () => {
    setShowWarning(false)
    setHasConfirmedFwCChange(true)
    setEditedJobOrder(prev => ({...prev, framework_contract_id: changedFwC.value}))
    setSelectedFwC(changedFwC)
  }
  const cancelFWCChange = () => {
    setShowWarning(false)
    setChangedFwC(null)
  }
  const changeHandlerSelectCountry = (e) => {
    setEditedJobOrder(prev => ({...prev, country: e.value}))
    setSelectedCountry(e)
  }
  const changeHandlerSelectSector = (e) => {
    setEditedJobOrder(prev => ({...prev, sector: e.value}))
    setSelectedSector(e)
  }
  const changeHandlerSelectEduLevel = (e) => {
    setEditedJobOrder(prev => ({...prev, education_level: e.value}))
    setSelectedEduLevel(e)
  }
  const changeHandlerSelectPlacementType = (e) => {
    setEditedJobOrder(prev => ({...prev, placement_type: e.value}))
    setSelectedPlacementType(e)
  }
  const changeHandlerSelectRequestType = (e) => {
    setEditedJobOrder(prev => ({...prev, request_type: e.value}))
    setSelectedRequestType(e)
  }
  const changeHandlerSelectMode = (e) => {
    setEditedJobOrder(prev => ({...prev, mode: e.value}))
    setSelectedMode(e)
  }
  const changeHandlerSelectPriority = (e) => {
    setEditedJobOrder(prev => ({...prev, priority: e.value}))
    setSelectedPriority(e)
  }
  const changeHandlerSelectIndustry = (e) => {
    setEditedJobOrder(prev => ({...prev, industry: e.label}))
    setSelectedIndustry(e)
  }
  const changeHandlerSelectLanguages = (e) => {
    let _selectedLanguages;
    if (!e) _selectedLanguages = [];
    else _selectedLanguages = e.map(el => el.value)
    setEditedJobOrder(prev => ({...prev, languages: _selectedLanguages}))
  }
  const changeHandlerSelectSkills = (e) => {
    let _selectedSkills;
    if (!e) _selectedSkills = [];
    else _selectedSkills = e.map(el => el)
    setEditedJobOrder(prev => ({...prev, skills: _selectedSkills}))
  }

  const changeHandlerTogglePublication = () => {
    if (editedJobOrder.is_published) {
      let publicationErrorsRemoved = formError.filter(error => error.msg.split(' ')[error.msg.split(' ')] != 'publication');
      setFormError(publicationErrorsRemoved)
    }
    setEditedJobOrder(prev => ({...prev, is_published: !editedJobOrder.is_published}))  
    
  }
  
  const postEditJobOrder = async () => {
    setIsEditing(true)
    let errors = []

    if (!editedJobOrder.title) errors.push({
      field: "Title*",
      msg: "This field is required"
    })

    if (!editedJobOrder.openings) errors.push({
      field: "Openings*",
      msg: "This field is required"
    })

    if (editedJobOrder.openings < 1) errors.push({
      field: "Openings*",
      msg: "Cannot be lower than 1"
    })

    if (!editedJobOrder.client_id) errors.push({
      field: "Client*",
      msg: "This field is required"
    })

    if (!editedJobOrder.country && !jobOrder?.country) errors.push({
      field: "Country*",
      msg: "This field is required"
    })
    //Only enforce required deadline non-legacy job orders
    if (!editedJobOrder.deadline && !editedJobOrder.legacy_id) errors.push({
      field: "Deadline*",
      msg: "This field is required"
    })
    if (!editedJobOrder.framework_contract_id) errors.push({
      field: "Framework contract*",
      msg: "This field is required"
    })

    if (editedJobOrder.is_published && (editedJobOrder.industry === null || editedJobOrder.price_range === '')) errors.push({
      field: "Industry*",
      msg: "This field is required for publication"
    })
    if (editedJobOrder.is_published && (editedJobOrder.price_range === null || editedJobOrder.price_range === '')) errors.push({
      field: "Price range*",
      msg: "This field is required for publication"
    })
    if (editedJobOrder.is_published && (editedJobOrder.mode === null || editedJobOrder.mode === '')) errors.push({
      field: "Mode*",
      msg: "This field is required for publication"
    })

    if (editedJobOrder.is_published && (!editedJobOrder?.skills || !editedJobOrder?.skills.length)) errors.push({
      field: "Skills*",
      msg: "This field is required for publication"
    })

    if (editedJobOrder.is_published && (!editedJobOrder.summary || editedJobOrder.summary === '')) errors.push({
      field: "Summary*",
      msg: "This field is required for publication"
    })

    if (errors.length) {
      titleInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      setIsEditing(false)
      return setFormError(errors);
    }

    setFormError([]);

    var descriptionRawContentState = convertToRaw(jobOrderDescription.getCurrentContent());
    var descriptionRawJSON = JSON.stringify(descriptionRawContentState)
    
    let data = { ...editedJobOrder, description: descriptionRawJSON }

    if (data.sales_price === '') data.sales_price = null
    if (data.yrs_of_exp === '') data.yrs_of_exp = null
    if (data.client_job_id === '') data.client_job_id = null
    if (data.price_range === '') data.price_range = null
    if (data.summary === '') data.summary = null
    let updateData = extractObjectDifferences(jobOrder, data)

    editJobOrder(jobOrder.id, updateData, userId).then((res) => {
      showAppNotification(
        appNotificationTypes.SUCCESS,
        "Success",
        "Job order edited successfully"
      );
      navigate(`/job-orders/${jobOrder.id}`, { replace: true });
      setIsEditing(false)
    }).catch(err => {
      setIsEditing(false)
    });
  }

  let sectorOptions = []

  SECTORS.forEach(sector => {
    sectorOptions.push({
    value: sector,
    label: sector      
    })
  })

  let eduLevelOptions = []

  EDUCATION_LEVELS.forEach(level => {
    eduLevelOptions.push({
      value: level,
      label: level
    })
  })
  

  useEffect(() => {
    let options = []
    COUNTRIES.forEach(country => {
      options.push({
        label: country.name,
        value: country.code
      })
      if (country.code == jobOrder.country) {
        setSelectedCountry({
          label: country.name,
          value: country.code
        })
      }
    })  
    setCountryOptions(options)
    
  }, [COUNTRIES])

  let placementTypeOptions = []

  JOB_ORDER_PLACEMENT_TYPES.forEach(type => {
    placementTypeOptions.push({
      label: type,
      value: type
    })
  })

  let requestTypeOptions = []

  JOB_ORDER_REQUEST_TYPES.forEach(type => {
    requestTypeOptions.push({
      label: type,
      value: type
    })
  })

  let modeOptions = []

  JOB_ORDER_MODES.forEach(mode => {
    modeOptions.push({
      value: mode, 
      label: mode
    })
  })

  let priorityOptions = []

  JOB_ORDER_PRIORITY.forEach(priority => {
    priorityOptions.push({
      label: priority,
      value: priority
    })
  })

  let industryOptions = []

  INDUSTRY_OPTIONS.forEach(industry => {
    industryOptions.push({
      label: industry,
      value: industry
    })
  })

  const verifyPropertyEdit = (property) => {
    if (jobOrder.created_by === userId) return true
    if (verifyArrayAccess('h_3_6_1_editable_properties', property)) return true
    return false
  }

  return (
    <div className="space-y-6">
      <Loader isLoading={isLoading}>
        <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'is_published')}>
          <FormWithSideTitle title='Publication' description="Toggle on to publish in Thaleria's website." toggle toggleValue={editedJobOrder?.is_published} onChange={() => changeHandlerTogglePublication()}/>
        </AccessWrapper>
        <FormWithSideTitle title='Basic information' description='Information on the job order' >
          <WarningModal
            title="Framework contract change"
            message="Changing the framework contract will remove all recruiters from this job order (after pressing Edit)."
            showWarning = {showWarning}
            setShowWarning = {setShowWarning}
            onClick1 = {confirmFWCChange}
            onClick2 = {cancelFWCChange}
            buttonText = 'Confirm change'
          />
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'title')}>
            <div ref={titleInputRef} className="col-span-6 sm:col-span-3">
              <TextInput label='Title*' disabled={!verifyPropertyEdit('title')} errors={formError} value={editedJobOrder.title} onChange={(e) => setEditedJobOrder(prev => ({...prev, title: e.target.value }) )}/>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <TextInput label='Level' disabled={!verifyPropertyEdit('title')} value={editedJobOrder.level} onChange={(e) => setEditedJobOrder(prev => ({...prev, level: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'sector')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label='Sector' disabled={!verifyPropertyEdit('sector')} errors={formError} selectedOption={selectedSector} options={sectorOptions} onChange={changeHandlerSelectSector}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'placement_type')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label='Placement type' disabled={!verifyPropertyEdit('placement_type')} errors={formError} selectedOption={selectedPlacementType} options={placementTypeOptions} onChange={changeHandlerSelectPlacementType}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'openings')}>
            <div className="col-span-6 sm:col-span-3">
              <NumberInput label='Openings*' disabled={!verifyPropertyEdit('openings')} errors={formError} min="1" value={editedJobOrder.openings} onChange={(e) => setEditedJobOrder(prev => ({...prev, openings: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'priority')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label='Priority' disabled={!verifyPropertyEdit('priority')} errors={formError} selectedOption={selectedPriority} options={priorityOptions} onChange={changeHandlerSelectPriority}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'sales_price')}>
            <div className="col-span-6 sm:col-span-3">
              <NumberInput label='Sales price (€/day)' disabled={!verifyPropertyEdit('sales_price')} errors={formError} min="0" max="9999999999,99" value={editedJobOrder.sales_price}  onChange={(e) => setEditedJobOrder(prev => ({...prev, sales_price: e.target.value}))}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'request_type')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label='Request type' disabled={!verifyPropertyEdit('request_type')} errors={formError} selectedOption={selectedRequestType} options={requestTypeOptions} onChange={changeHandlerSelectRequestType}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'mode')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label={editedJobOrder.is_published ? 'Mode*' : 'Mode'} disabled={!verifyPropertyEdit('mode')} errors={formError} selectedOption={selectedMode} options={modeOptions} onChange={changeHandlerSelectMode}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'industry')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label={editedJobOrder.is_published ? 'Industry*' : 'Industry'} disabled={!verifyPropertyEdit('is_published')} errors={formError} selectedOption={selectedIndustry} options={industryOptions} onChange={changeHandlerSelectIndustry}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'prince_range')}>
            <div className="col-span-6 sm:col-span-3">
              <TextInput label={editedJobOrder.is_published ? "Price range*" : "Price range"} disabled={!verifyPropertyEdit('price_range')} errors={formError} value={editedJobOrder.price_range} onChange={e => setEditedJobOrder(prev => ({...prev, price_range: e.target.value}))}/>
            </div>
          </AccessWrapper>
        </FormWithSideTitle>
        <FormWithSideTitle title="Position details" description="Details about the position">
          <div className="col-span-6 sm:col-span-3">
            <NumberInput label='Years of experience' errors={formError} placeholder={null} min="0" value={editedJobOrder.yrs_of_exp} onChange={(e) => setEditedJobOrder(prev => ({...prev, yrs_of_exp: e.target.value}))}/>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <ReactSelect label='Education level' errors={formError} selectedOption={selectedEduLevel} options={eduLevelOptions} onChange={changeHandlerSelectEduLevel}/>
          </div>
          {currentSkills.length || !currentSkills ?
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'skills')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactCreatableSelect label={editedJobOrder.is_published ? "Skills*" : "Skills"} errors={formError} disabled={!verifyPropertyEdit('skills')} isMulti selectedOptions={currentSkills} options={skillOptions} onChange={changeHandlerSelectSkills}/>
            </div>
          </AccessWrapper>
          :
          <></>
          }
          {currentLanguages.length || !currentLanguages ? 
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'languages')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label="Languages" errors={formError} disabled={!verifyPropertyEdit('languages')} isMulti selectedOptions={currentLanguages} options={languageOptions} onChange={changeHandlerSelectLanguages}/>
            </div>
          </AccessWrapper>
          :
          <></>
          }
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'psc_required')}>
            <div className="col-span-6">
              <Toggle label='PSC required' disabled={!verifyPropertyEdit('psc_required')} errors={formError} value={editedJobOrder.psc_required} description="Toggle on if PSC is required for this job order" onChange={() => setEditedJobOrder(prev => ({...prev, psc_required: !editedJobOrder.psc_required}))}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'summary')}>
            <div className="col-span-6">
              <TextArea label={editedJobOrder.is_published ? 'Summary*' : 'Summary'} rows={4} disabled={!verifyPropertyEdit('summary')} value={editedJobOrder?.summary} onChange={e => {setEditedJobOrder(prev => ({...prev, summary: e.target.value}))}} errors={formError} placeholder='Write here...'/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'description')}>
            <div className="col-span-6">
              <RichTextEditor label='Description' readOnly={!verifyPropertyEdit('description')} editorState={jobOrderDescription} setEditorState={setJobOrderDescription} placeholder='Write here...' errors={formError}/>
            </div>
          </AccessWrapper>
        </FormWithSideTitle>
        <FormWithSideTitle title="Duration and location" description="Job order's deadline, duration and location">
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'duration')}>
            <div className="col-span-6 sm:col-span-3">
              <TextInput label='Duration' disabled={!verifyPropertyEdit('duration')} errors={formError} value={editedJobOrder.duration} onChange={(e) => setEditedJobOrder(prev => ({...prev, duration: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'deadline')}>
            <div className="col-span-6">
              <DateInput label={`Deadline${editedJobOrder.legacy_id ? "" : "*"}`} disabled={!verifyPropertyEdit('deadline')} errors={formError} selected={editedJobOrder.deadline && new Date(editedJobOrder.deadline)} onChange={e => setEditedJobOrder(prev => ({ ...prev, deadline: e}))} />
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'city')}>
            <div className="col-span-6 sm:col-span-3">
              <TextInput label='City' disabled={!verifyPropertyEdit('city')} errors={formError} value={editedJobOrder.city} onChange={(e) => setEditedJobOrder(prev => ({...prev, city: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'country')}>
            <div className="col-span-6 sm:col-span-3">
              <ReactSelect label='Country*' disabled={!verifyPropertyEdit('country')} errors={formError} selectedOption={selectedCountry} options={countryOptions} onChange={changeHandlerSelectCountry}/>
            </div>
          </AccessWrapper>
        </FormWithSideTitle>
        <FormWithSideTitle title='Client information' description='Client, client job id and framework contract reference' >
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'client_id')}>
            <div className="col-span-6">
              <ReactSelect label='Client*' disabled={!verifyPropertyEdit('client_id')} errors={formError} selectedOption={selectedClient} options={clientOptions} onChange={changeHandlerSelectClient}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'client_job_id')}>
            <div className="col-span-6">
              <TextInput label='Client Job ID' disabled={!verifyPropertyEdit('client_job_id')} errors={formError} value={editedJobOrder.client_job_id} onChange={(e) => setEditedJobOrder(prev => ({...prev, client_job_id: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess('h_1_1_viewable_properties', 'framework_contract_id')}>
            <div className="col-span-6">
              <ReactSelect label='Framework contract*' disabled={!verifyPropertyEdit('framework_contract_id')} errors={formError} selectedOption={selectedFwC} options={fwCOptions} onChange={changeHandlerSelectFwC}/>
            </div>
          </AccessWrapper>
        </FormWithSideTitle>
        <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row">
          <WhiteButton text='Cancel' onClick={() => navigateBack(`/job-orders`) }/>
          <PrimaryButton text='Edit' darkRing isLoading={isEditing} onClick={postEditJobOrder} disabled={!showEditButton} />
          {changedFwC && (changedFwC?.value != jobOrder?.framework_contract_id) && <span className="flex mt-auto py-2"><ExclamationCircleIcon className="text-sm h-5 w-5 mr-1 mt-0.5 text-yellow-400"/>Pressing edit will remove all recruiters</span>}
        </div>
      </Loader>
    </div>
  )
}

export default EditJobOrderForm