const releaseNotes = [
  {
    version: `1.6.4`,
    date: "2025/02/21",
    markdown: `
### New Features 
- You can now look up candidates outside your scope using their linkedin handle or linkedin page's url

### Fixes and improvements
- Added LinkedIn handle property to Candidate
- Candidate's e-mail addresses now saved to database with only lower cases characters
- Added a loader to make it harder to create duplicate Job Orders or Candidates (same thing for duplicate Edits)
- Fixed error handling when fetching published job orders by id
`,
  },
  {
    version: `1.6.3`,
    date: "2025/01/16",
    markdown: `
### Fixes and improvements
- Fixed notice period to allow null values both on creation and when editing the candidate
- Adding a blacklisted candidate to a Job Order will now generate a warning to the user
- Added more specific error messages to some backend errors
- Links in e-mail notifications surrounding job orders and candidates will now redirect to the specific resource
`,
  },
  {
    version: `1.6.2`,
    date: "2023/09/15",
    markdown: `
### New Features 
- You can now edit candidate owners

### Fixes and improvements
- Removed ghost properties that were appearing in Add Role "user properties" list
- Fixed token refresh issue that was causing the screen to go blank after renewing a token
`,
  },
  {
    version: `1.6.1`,
    date: "2023/07/18",
    markdown: `
### New Features 
- You can now search by Job order ID on the global search
- You can now add more symbols to candidate names (č, apostrophe, hyphen, etc)
- Candidates can now be sorted by creation date (which is now also the default setting)


### Fixes and improvements
Several security related fixes including:
- More secure passwords are now enforced (8 characters, with lower and uppercase letters, numbers and symbols)
- Added frontend server security headers
- Error Logging does not communicate app info
- Less PII shared on token
- Account freeze based on logging attempts now enabled (previously it was only by IP)
- Additional security checks on uploaded files
- Logging out will disable token validity
`,
  },
  {
    version: `1.6`,
    date: "2023/03/10",
    markdown: `### New Features 
- Job orders can now be published from the app on Thaleria's company website. Additional information will be required when publishing
- Can now download csv file containing the data from CATS tables through the filters/columns modal (active filters and columns will also be applied to the file)
    
### Fixes and improvements
- Can now change candidate applications in mobile devices
- Fixed issue preventing duplication of job orders
- Fixed context menu position (left-click menu in tables) being offset from the mouse pointer
- Fixed issue with job orders pagination (switching pages would add or remove shown job orders)
- Global search now only required 3 characters and fixed search icon
- Improved responsiveness throughout the app
- Fixes to candidate properties appearing in when creating/editing roles
- Fixed infinite loading in tables when there is no data to display
- Fixed issue where blacklisting a candidate was not working properly
    `,
  },
  {
    version: `1.5`,
    date: "2022/12/03",
    markdown: `### New Features
- Added a global search bar. You can now search our database for results matching your query.
- Added a feature to blacklist candidates. You can now blacklist specific candidates.

### Fixes and improvements
- Revamped the way the app manages job orders. This should notably improve performance and reduce loading speed on job order related pages.
- When you edit, add or delete a job order, it will be updated in real time for any other logged user, without the user requiring to refresh the page (only applies to job orders).
- All pages should be fully mobile friendly, without UI bugs or major issues.
`,
  },
  {
    version: `1.4.6`,
    date: "2022/10/02",
    markdown: `### Bugs and fixes
- Candidate's can_relocate property now appears in candidate table, details and edit
- Fixed issue in table filters where the page wouldn't display if the filterValue did not return an array
`,
  },
  {
    version: "1.4.5",
    date: "2022/09/12",
    markdown: `### Bugs and fixes
- Removed integer restraint from users custom price margin`,
  },
  {
    version: "1.4.4",
    date: "2022/09/07",
    markdown: `### Fixes
- User's or user group's price margin now applies also in Job order table.`,
  },
  {
    version: "1.4.3",
    date: "2022/11/06",
    markdown: `### Bugs and improvements
- USERGROUPS: Removed integer restriction on price margin and allowed digits on name`,
  },
  {
    version: "1.4.2",
    date: "2022/11/06",
    markdown: `### Bugs and fixes
- Removed '-' from prohibited characters on file uploads`,
  },
  {
    version: "1.4.1",
    date: "2022/11/05",
    markdown: `### Bugs and improvements
- Fixed bug related to add job order where a user without manage_fwc but with create_job_orders could not create job order because of fetch fwc access issues`,
  },
  {
    version: "1.4.0",
    date: "2022/11/01",
    markdown: `### New Features
- Updated color theme to better reflect Thaleria branding
- Added Client Job ID to Job Order table
- On the home dashboard, we can now select custom dates on the "Past applications" graph
- Updating a job order status to Cancelled or Archived will automatically sort open applications to 'Not in consideration' status before applying the job order status change (this also applies to batch job order status updates)
- Two new optional fields were added to Job Orders: Mode (options: Onsite, Near-site, Near-site (flexible), Remote) + Request type (options: Official, Proactive)

### Fixes and improvements
- We can now edit cities for clients
- Filter icon now appears next to columns that have applied filters
- Countries are now displayed with country codes (eg. BE instead of Belgium) on tables
- On tables, sort by date columns now properly orders dates
- We can only upload files without special characters on the file name to avoid related bugs
- We can no longer update applications if the job order is either archived or cancelled
`,
  },
];

export default releaseNotes;
