import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate } from 'react-router'
import useApi from '../../store/api/apiContext'
import role from '../../helpers/enum/role'
import CandidatesTable from '../../containers/candidates/CandidatesTable'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import CandidateLookup from '../../containers/candidates/CandidateLookup'

function Candidates({ access }) {
  const [ candidates, setCandidates ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ showSearchModal, setShowSearchModal] = useState(false)

  const { fetchCandidates } = useApi();

  const fetchCandidatesFromApi = async (source) => {
    fetchCandidates('table').then(res => {
      setCandidates(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchCandidatesFromApi(source);
    return () => {
      source.cancel();
    }
  }, [])

  const renderTopSection = () => {
    return (
      <>
        <SecondaryButton text={'Candidate lookup'} onClick={() => setShowSearchModal(true)} />
        <PrimaryButton access={[role.g_candidates.g_1_create_my_candidate, role.g_candidates.g_4_2_create_candidates]} text='Add candidate' linkTo='/candidates/add'/>
      </>
    )
  }

  return (
    <PageWrapper title={'Candidates'} topSection={renderTopSection()} access={access}>
      <CandidatesTable candidates={candidates} isLoading={isLoading}/>
      <CandidateLookup show={showSearchModal} hide={() => setShowSearchModal(false)}/>
    </PageWrapper>
  )
}

export default Candidates