import React, { useState, useEffect } from "react";
import useApi from '../../store/api/apiContext';
import { SearchIcon, IdentificationIcon, ChevronRightIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import Modal from "../../components/modal/Modal";
import PulseLoader from "react-spinners/PulseLoader";
import { APP_STYLES } from "../../config";
import InfoTooltip from '../../components/tooltips/InfoTooltip';

const CandidateLookup = ({ show, hide }) => {
  const { candidateLookup } = useApi();
  const [query, setQuery] = useState('');
  const [candidate, setCandidate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [blacklistComment, setBlacklistComment] = useState(null)
  let timeoutId;

  const getCandidate = () => {
    if (query.length > 2) {
      setIsLoading(true);
      candidateLookup(query).then((res) => {
        setCandidate(res.data?.candidate || { message: "No candidate found" });
        setBlacklistComment(res?.data?.blacklistComment?.message || null)
        setIsLoading(false);
      });
    } else {
      setCandidate({ message: "Please enter at least 3 characters." });
    }
  };

  const timeoutSearch = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(getCandidate, 500);
  };

  useEffect(() => {
    if (show && searchInput) searchInput.focus();
  }, [show, searchInput]);

  useEffect(() => {
    if (query) timeoutSearch();
    return () => clearTimeout(timeoutId);
  }, [query]);

  const linkToLinkedin = (handle) => {
    return (
      <div className='flex gap-x-1 items-center'>
        <span>www.linkedin.com/in/{handle}</span>
        <ExternalLinkIcon className="h-4 w-4 cursor-pointer text-thaleria-orange-700 hover:text-thaleria-orange-500"
            onClick={() => window.open(`https://www.linkedin.com/in/${handle}`)}/>
      </div>
    )
  }

  return (
    <div>
      <Modal show={show} hide={hide} size="lg" noPadding>
        <div className="py-2">
          <div className="px-5 flex space-x-4 items-center border-b-2">
            <SearchIcon className="h-6 w-6" />
            <input
              type="text"
              ref={setSearchInput}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search candidates..."
              className="border-none w-full focus:ring-0"
            />
          </div>
          {isLoading && (
            <div className="text-center pt-10 pb-1">
              <PulseLoader color={APP_STYLES.primaryColor} />
            </div>
          )}
          {!query && !isLoading ? (
            <div className="text-gray-500 text-sm text-center pt-4 pb-2">
              This window allows you to search Candidates within our database.
              <br />
              You can use a potential candidate's LinkedIn handle, and if a match is found, their name and Blacklisted status will be returned.
            </div>
          ) : (
            <div className="px-5 pt-4 pb-2">
              {candidate && candidate.first_names ? (
                <div className="w-full h-8 text-base flex justify-between items-center">
                  <div className='flex gap-x-4 items-center'>
                    <IdentificationIcon className="w-8 h-8" />
                    <div className='flex flex-col gap-y-1'>
                      <span>{candidate.first_names + " " + candidate.last_names}</span>
                      <span className='text-sm text-gray-500'>{candidate?.linkedin_handle && linkToLinkedin(candidate.linkedin_handle)}</span>
                    </div>
                  </div>
                  <div className='flex gap-x-1 items-center'>
                  <span className={`${candidate.blacklisted ? 'text-red-500 font-semibold' : 'text-green-500'}`}>
                    {candidate.blacklisted ? "Blacklisted" : "Not Blacklisted"}
                  </span>
                  {blacklistComment ? <InfoTooltip iconClassName={'h-5 w-5 mt-[2.5px] text-red-500 text-sm'}><div className="w-60 h-max">{blacklistComment}</div></InfoTooltip> : ''}
                  </div>
                </div>
              ) : candidate?.message && (
                <div className="text-base text-center pt-2 pb-2">
                  {candidate.message}
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CandidateLookup;
