import React, { useState, useEffect, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import NumberInput from '../../components/inputs/NumberInput'
import DateInput from '../../components/inputs/DateInput/DateInput'
import ReactSelect from '../../components/inputs/ReactSelect';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import useApi from '../../store/api/apiContext';
import axios from 'axios';
import { EDUCATION_LEVELS, SECTORS, JOB_ORDER_PLACEMENT_TYPES, JOB_ORDER_REQUEST_TYPES, INDUSTRY_OPTIONS, JOB_ORDER_MODES, JOB_ORDER_PRIORITY } from '../../helpers/selectOptions';
import COUNTRIES from '../../helpers/countryList'
import { jobStatuses, jobStatusIds } from '../../helpers/enum/jobStatuses'
import RichTextEditor from '../../components/inputs/RichTextEditor/RichTextEditor';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import Toggle from '../../components/inputs/Toggle';
import ReactCreatableSelect from '../../components/inputs/ReactCreatableSelect';
import useJobOrders from '../../store/jobOrders/jobOrdersContext';
import TextArea from '../../components/inputs/TextArea';

function AddJobOrderForm({template}) {

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  const titleInputRef = useRef(null)
  
  const navigate = useNavigate();
  const { fetchLanguages, fetchSkills, fetchFrameworkContracts, fetchClients } = useApi();
  const { addJobOrder } = useJobOrders();
  const { showAppNotification } = useAppNotification();
  const [ newJobOrder, setNewJobOrder ] = useState({
    psc_required: false,
    is_published: false
  })
  const [ newStatusLog, setNewStatusLog ] = useState({status: jobStatusIds.active})
  const [ formError, setFormError ] = useState([])
  const [ clientOptions, setClientOptions ] = useState([])
  const [ fwCOptions, setFwCOptions ] = useState([])
  const [ selectedClient, setSelectedClient ] = useState()
  const [ selectedFwC, setSelectedFwC ] = useState()
  const [ selectedCountry, setSelectedCountry ] = useState()
  const [ selectedSector, setSelectedSector ] = useState()
  const [ selectedEduLevel, setSelectedEduLevel ] = useState()
  const [ selectedPlacementType, setSelectedPlacementType ] = useState()
  const [ selectedRequestType, setSelectedRequestType ] = useState()
  const [ selectedMode, setSelectedMode ] = useState();
  const [ selectedStatus, setSelectedStatus ] = useState({value: 1, label: "Active"})
  const [ selectedPriority, setSelectedPriority ] = useState()
  const [ selectedLanguages, setSelectedLanguages ] = useState([])
  const [ selectedSkills, setSelectedSkills ] = useState([])
  const [ selectedIndustry, setSelectedIndustry ] = useState()
  const [ languageOptions, setLanguageOptions] = useState([])
  const [ skillOptions, setSkillOptions ] = useState([])
  const [isCreating, setIsCreating] = useState(false)

  const _fetchClients = async (source) => {
    //Check to see if what we should use (also from FwCs)
    await fetchClients(source.token, 'jobOrder').then(res => {
      let clients = [];
      res.data.map(client => clients.push({
        value: client.id,
        label: client.name
      }))
      setClientOptions(clients)
      if (template) {
        let _client = clients.find(client => client.value == template.client_id)
        setSelectedClient(_client)
      }
    })
  }

  const fetchFwContracts = async(source) => {
    await fetchFrameworkContracts(source.token, 'jobOrder').then(res => {
      let fwContracts =[];
      res.data.map(fwContract => fwContracts.push({
        value: fwContract.id,
        label: fwContract.contract_ref
      }))
      setFwCOptions(fwContracts)
      if (template) {
        let _fwC = fwContracts.find(fwc => fwc.value == template.framework_contract_id)
        setSelectedFwC(_fwC)
      }
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    let languages = [];
    let skills = [];

    _fetchClients(source);
    fetchFwContracts(source);
    fetchLanguages(source).then(res => {
      res.data.map(language => languages.push({
        value: language.id,
        label: language.name
      }))
    })
    fetchSkills(source).then(res => {
      res.data.map(skill => skills.push({
        value: skill.id,
        label: skill.name
      }))
    })
    setLanguageOptions(languages)
    setSkillOptions(skills)

    if (template) {
      
      let jobOrder = template;
      
      setSelectedCountry({
        value: template.country,
        label: template.country
      })
      setSelectedSector({
        value: template.sector,
        label: template.sector
      })
      setSelectedPlacementType({
        value: template.placement_type,
        label: template.placement_type
      })
      setSelectedRequestType({
        value: template.request_type,
        label: template.request_type
      })
      setSelectedMode({
        value: template.mode,
        label: template.mode
      })
      setSelectedPriority({
        value: template.priority,
        label: template.priority
      })
      setSelectedEduLevel({
        value: template.education_level,
        label: template.education_level
      })
      setSelectedIndustry({
        value: template.industry,
        label: template.industry
      })
      template?.languages.forEach(language => {
        setSelectedLanguages(prev => ([...prev, {value: language.id, label: language.name}]))
      })
      template?.skills.forEach(skill => {
        setSelectedSkills(prev => ([...prev, {value: skill.id, label: skill.name}]))
      })

      delete jobOrder.skills
      delete jobOrder.languages
      delete jobOrder?.published_at
      delete jobOrder?.current_status
      
      if (jobOrder.description) {
        var convertedJSON = JSON.parse(jobOrder.description);
        setEditorState(EditorState.createWithContent(convertFromRaw(convertedJSON)))
      }
      setNewJobOrder(jobOrder)
    }
    return () => {
      source.cancel();
    };
  }, [])

  const changeHandlerSelectClient = (e) => {
    setNewJobOrder(prev => ({...prev, client_id: e.value}))
    setSelectedClient(e)
  }
  const changeHandlerSelectFwC = (e) => {
    setNewJobOrder(prev => ({...prev, framework_contract_id: e.value}))
    setSelectedFwC(e)
  }
  const changeHandlerSelectCountry = (e) => {
    setNewJobOrder(prev => ({...prev, country: e.value}))
    setSelectedCountry(e)
  }
  const changeHandlerSelectSector = (e) => {
    setNewJobOrder(prev => ({...prev, sector: e.value}))
    setSelectedSector(e)
  }
  const changeHandlerSelectEduLevel = (e) => {
    setNewJobOrder(prev => ({...prev, education_level: e.value}))
    setSelectedEduLevel(e)
  }
  const changeHandlerSelectPlacementType = (e) => {
    setNewJobOrder(prev => ({...prev, placement_type: e.value}))
    setSelectedPlacementType(e)
  }
  const changeHandlerSelectRequestType = (e) => {
    setNewJobOrder(prev => ({...prev, request_type: e.value}))
    setSelectedRequestType(e)
  }
  const changeHandlerSelectMode = (e) => {
    setNewJobOrder(prev => ({...prev, mode: e.value}))
    setSelectedMode(e)
  }
  const changeHandlerSelectStatus = (e) => {
    setNewStatusLog({status: e.value})
    setSelectedStatus(e)
  }
  const changeHandlerSelectPriority = (e) => {
    setNewJobOrder(prev => ({...prev, priority: e.value}))
    setSelectedPriority(e)
  }

  const changeHandlerSelectIndustry = (e) => {
    setNewJobOrder(prev => ({...prev, industry: e.value}))
    setSelectedIndustry(e)
  }

  const changeHandlerTogglePublication = () => {
    if (newJobOrder?.publication) {
      let publicationErrorsRemoved = formError.filter(error => error.msg.split(' ')[error.msg.split(' ')] != 'publication');
      setFormError(publicationErrorsRemoved)
    }
    setNewJobOrder(prev => ({...prev, is_published: !newJobOrder.is_published}))
  }

  const changeHandlerSelectLanguages = (e) => {
    let _selectedLanguages;
    if (!e) _selectedLanguages = [];
    else _selectedLanguages = e.map(el => el)
    setSelectedLanguages(_selectedLanguages)
  }
  const changeHandlerSelectSkills = (e) => {
    let _selectedSkills;
    if(!e) _selectedSkills = [];
    else _selectedSkills = e.map(el => el)
    setSelectedSkills(_selectedSkills)
  }
  
  const postAddJobOrder = async () => {
    setIsCreating(true)
    let errors = []
    
    if (!newJobOrder.title) errors.push({
      field: "Title*",
      msg: "This field is required"
    })

    if (!newJobOrder.openings) errors.push({
      field: "Openings*",
      msg: "This field is required"
    })

    if (newJobOrder.openings < 1) errors.push({
      field: "Openings*",
      msg: "Cannot be lower than 1"
    })

    if (!newJobOrder.client_id) errors.push({
      field: "Client*",
      msg: "This field is required"
    })

    if (!newJobOrder.country) errors.push({
      field: "Country*",
      msg: "This field is required"
    })

    if (!newJobOrder.deadline) errors.push({
      field: "Deadline*",
      msg: "This field is required"
    })
    if (!newJobOrder.framework_contract_id) errors.push({
      field: "Framework contract*",
      msg: "This field is required"
    })

    if (newJobOrder.is_published && (newJobOrder.industry === null || newJobOrder.price_range === '')) errors.push({
      field: "Industry*",
      msg: "This field is required for publication"
    })
    if (newJobOrder.is_published && (newJobOrder.price_range === null || newJobOrder.price_range === '')) errors.push({
      field: "Price range*",
      msg: "This field is required for publication"
    })

    if (newJobOrder.is_published && !selectedSkills.length) errors.push({
      field: "Skills*",
      msg: "This field is required for publication"
    })

    if (newJobOrder.is_published && (!newJobOrder.summary || newJobOrder.summary === '')) errors.push({
      field: "Summary*",
      msg: "This field is required for publication"
    })
    if (newJobOrder.is_published && (!newJobOrder.mode || newJobOrder.mode === '')) errors.push({
      field: "Mode*",
      msg: "This field is required for publication"
    })

    if (errors.length) {
      titleInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      setIsCreating(false)
      return setFormError(errors);
    }

    setFormError([]);
    
    //prepare description field rich text

    var rawContentState = convertToRaw(editorState.getCurrentContent());
    var rawJSON = JSON.stringify(rawContentState)

    let data = { ...newJobOrder, description: rawJSON }


    if (data.sales_price == '') data.sales_price = null
    if (data.yrs_of_exp == '') data.yrs_of_exp = null
    if (data.client_job_id == '') data.client_job_id = null
    if (data.price_range == '') data.price_range = null
    
    if (selectedSkills.length) data.skills = selectedSkills
    if (selectedLanguages.length) data.languages = selectedLanguages.map(el => el.value)

    Object.keys(data).forEach(key => {
      if (data[key] === null) delete data[key]
    })

    addJobOrder(data, newStatusLog).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Job order added successfully');
      navigate('/job-orders', { replace: true })
      setIsCreating(false)
    }).catch(err => {
      setIsCreating(false)
    })
  }


  let sectorOptions = []

  SECTORS.forEach(sector => {
    sectorOptions.push({
    value: sector,
    label: sector      
    })
  })

  let eduLevelOptions = []

  EDUCATION_LEVELS.forEach(level => {
    eduLevelOptions.push({
      value: level,
      label: level
    })
  })
  
  let countryOptions = []

  COUNTRIES.forEach(country => {
    countryOptions.push({
      label: country.name,
      value: country.code
    })
  })
  
  let placementTypeOptions = []

  JOB_ORDER_PLACEMENT_TYPES.forEach(type => {
    placementTypeOptions.push({
      label: type,
      value: type
    })
  })

  let requestTypeOptions = [];

  JOB_ORDER_REQUEST_TYPES.forEach(type => {
    requestTypeOptions.push({
      label: type,
      value: type
    })
  })

  let modeOptions = [];

  JOB_ORDER_MODES.forEach(mode => {
    modeOptions.push({
      label: mode,
      value: mode
    })
  })

  let statusOptions = []

  Object.keys(jobStatuses).forEach(status => {
    statusOptions.push({
      label: jobStatuses[status],
      value: status
    })
  })

  let priorityOptions = []

  JOB_ORDER_PRIORITY.forEach(priority => {
    priorityOptions.push({
      label: priority,
      value: priority
    })
  })

  let industryOptions = []

  INDUSTRY_OPTIONS.forEach(industry => {
    industryOptions.push({
      label: industry,
      value: industry
    })
  })
  
  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Publication' description="Toggle on to publish in Thaleria's website." toggle toggleValue={newJobOrder?.is_published} onChange={() => changeHandlerTogglePublication()}/>
      <FormWithSideTitle title='Basic information' description='Information on the job order' >
        <div ref={titleInputRef} className="col-span-6 sm:col-span-3">
          <TextInput label='Title*' errors={formError} value={newJobOrder.title} onChange={(e) => setNewJobOrder(prev => ({...prev, title: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInput label='Level' value={newJobOrder.level} onChange={(e) => setNewJobOrder(prev => ({...prev, level: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Status' errors={formError} value={selectedStatus} options={statusOptions} onChange={changeHandlerSelectStatus}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Sector' errors={formError} value={selectedSector} options={sectorOptions} onChange={changeHandlerSelectSector}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Placement type' errors={formError} value={selectedPlacementType} options={placementTypeOptions} onChange={changeHandlerSelectPlacementType}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <NumberInput label='Openings*' errors={formError} min="1" value={newJobOrder.openings} onChange={(e) => setNewJobOrder(prev => ({...prev, openings: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Priority' errors={formError} value={selectedPriority} options={priorityOptions} onChange={changeHandlerSelectPriority}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <NumberInput label='Sales price (€/day)' errors={formError} min="0" max="9999999999,99" value={newJobOrder.sales_price}  onChange={(e) => setNewJobOrder(prev => ({...prev, sales_price: e.target.value}))}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Request type' errors={formError} value={selectedRequestType} options={requestTypeOptions} onChange={changeHandlerSelectRequestType}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label={newJobOrder.is_published ? 'Mode*' : 'Mode'} errors={formError} value={selectedMode} options={modeOptions} onChange={changeHandlerSelectMode}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label={newJobOrder.is_published ? 'Industry*' : 'Industry'} errors={formError} selectedOption={selectedIndustry} options={industryOptions} onChange={changeHandlerSelectIndustry}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInput label={newJobOrder.is_published ? "Price range*" : "Price range"} errors={formError} value={newJobOrder.price_range} onChange={e => setNewJobOrder(prev => ({...prev, price_range: e.target.value}))}/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title="Position details" description="Details about the position">
        <div className="col-span-6 sm:col-span-3">
          <NumberInput label='Years of experience' errors={formError} min="0" value={newJobOrder.yrs_of_exp} onChange={(e) => setNewJobOrder(prev => ({...prev, yrs_of_exp: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Education level' errors={formError} selectedOption={selectedEduLevel} options={eduLevelOptions} onChange={changeHandlerSelectEduLevel}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Languages' isMulti errors={formError} value={selectedLanguages} options={languageOptions} onChange={changeHandlerSelectLanguages}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactCreatableSelect label={newJobOrder.is_published ? 'Skills*' : 'Skills'} isMulti errors={formError} value={selectedSkills} options={skillOptions} onChange={changeHandlerSelectSkills}/>
        </div>
        <div className="col-span-6">
          <Toggle label='PSC required' errors={formError} description="Toggle on if PSC is required for this job order" value={newJobOrder.psc_required} onChange={() => setNewJobOrder(prev => ({...prev, psc_required: !newJobOrder.psc_required}))}/>
        </div>
        <div className="col-span-6">
          <TextArea label={newJobOrder.is_published ? 'Summary*' : 'Summary'} value={newJobOrder?.summary} onChange={e => {setNewJobOrder(prev => ({...prev, summary: e.target.value}))}} errors={formError} placeholder='Write here...'/>
        </div>
        <div className="col-span-6">
          <RichTextEditor label='Description' editorState={editorState} setEditorState={setEditorState} placeholder='Write here...'/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title="Duration and deadline" description="Job order's deadline and duration">
      <div className="col-span-6 sm:col-span-3">
          <TextInput label='Duration' errors={formError} value={newJobOrder.duration} onChange={(e) => setNewJobOrder(prev => ({...prev, duration: e.target.value }) )}/>
        </div>
        <div className="col-span-6">
          <DateInput label="Deadline*" errors={formError} selected={newJobOrder.deadline && new Date(newJobOrder.deadline)} onChange={e => setNewJobOrder(prev => ({ ...prev, deadline: e}))} />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInput label='City' errors={formError} value={newJobOrder.city} onChange={(e) => setNewJobOrder(prev => ({...prev, city: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Country*' errors={formError} selectedOption={selectedCountry} options={countryOptions} onChange={changeHandlerSelectCountry}/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title='Client information' description='Client, client job id and framework contract reference' >
        <div className="col-span-6">
          <ReactSelect label='Client*' errors={formError} value={selectedClient} options={clientOptions} onChange={changeHandlerSelectClient}/>
        </div>
        <div className="col-span-6">
          <TextInput label='Client Job ID' errors={formError} value={newJobOrder.client_job_id} onChange={(e) => setNewJobOrder(prev => ({...prev, client_job_id: e.target.value }) )}/>
        </div>
        <div className="col-span-6">
          <ReactSelect label='Framework contract*' errors={formError} value={selectedFwC} options={fwCOptions} onChange={changeHandlerSelectFwC}/>
        </div>
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigate('/job-orders', {replace: true}) }/>
        <PrimaryButton text='Add' darkRing isLoading={isCreating} onClick={postAddJobOrder}/>
      </div>
    </div>
  )
}

export default AddJobOrderForm